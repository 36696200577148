import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useEmpleadosList() {
  // Use toast
  const toast = useToast()

  const refEmpleadoListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'matricula', label: 'Matricula', sortable: true },
    { key: 'apellidos', label: 'Empleado', sortable: true },
    { key: 'contact', label: 'Contacto', sortable: false },
    { key: 'estado', label: 'Estado', sortable: true },
    { key: 'contrato', label: 'Contrato', sortable: false },
    { key: 'actions', label: '' },
  ]
  const perPage = ref(50)
  const totalEmpleados = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('apellidos')
  const isSortDirDesc = ref(false)
  const filterInicioContrato = ref(null)
  const filterFinContrato = ref(null)
  const filterDepartamento = ref(null)
  const filterEstadoEmpleado = ref(null)
  const isFiltering = ref(false)
  const isEmpleadoState = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refEmpleadoListTable.value ? refEmpleadoListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEmpleados.value,
    }
  })

  const fetchEmpleadosToState = () => {
    const body = isFiltering.value ? {
      search: searchQuery.value,
      dpto: filterDepartamento.value ? filterDepartamento.value.key : '',
      contrato_inicio: filterInicioContrato.value,
      contrato_fin: filterFinContrato.value,
      estado: filterEstadoEmpleado.value ? filterEstadoEmpleado.value : '',
      paginate: perPage.value,
    } : {
      search: searchQuery.value,
      paginate: perPage.value,
    }

    store
      .dispatch('app-empleado/fetchEmpleados', {
        body,
        headers: {
          'X-Sort': `empleados.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          paginate: true,
          page: currentPage.value,
        },
      })
      .then(res => {
        const filterActive = res.data.filter(el => el.estado === 1)
        const filterInactive = res.data.filter(el => el.estado === 0)

        totalEmpleados.value = res.total

        let displayItems = filterActive
        if (filterActive.length < perPage.value) {
          const needed = perPage.value - filterActive.length
          displayItems = filterActive.concat(filterInactive.slice(0, needed))
        }

        store.commit('app-empleado/SET_EMPLEADOS', displayItems)

        if (refEmpleadoListTable.value) {
          refEmpleadoListTable.value.refresh()
        } else {
          console.error('refEmpleadoListTable.value é null. Tente novamente mais tarde.')
        }

        if (displayItems.length === 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'No Results',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
              message: 'No employees found with the current filter criteria.',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching empleados list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    if (refEmpleadoListTable.value) refEmpleadoListTable.value.refresh()
  }

  const filterData = () => {
    isFiltering.value = true

    if (isEmpleadoState.value) {
      fetchEmpleadosToState()
    }

    if (refEmpleadoListTable.value) refetchData()
  }

  const clearFilter = () => {
    isFiltering.value = false
    filterInicioContrato.value = null
    filterFinContrato.value = null
    filterDepartamento.value = null
    filterEstadoEmpleado.value = null

    if (isEmpleadoState.value) {
      fetchEmpleadosToState()
    }

    refetchData()
  }

  watch([currentPage, perPage], () => {
    if (isEmpleadoState.value) {
      fetchEmpleadosToState()
    }
    refetchData()
  })

  const fetchEmpleados = (ctx, callback) => {
    const body = isFiltering.value ? {
      search: searchQuery.value,
      dpto: filterDepartamento.value ? filterDepartamento.value.key : '',
      contrato_inicio: filterInicioContrato.value,
      contrato_fin: filterFinContrato.value,
      estado: filterEstadoEmpleado.value ? filterEstadoEmpleado.value.key : '',
      paginate: perPage.value,
    } : {
      search: searchQuery.value,
      paginate: perPage.value,
    }
    store
      .dispatch('app-empleado/fetchEmpleados', {
        body,
        headers: {
          'X-Sort': `empleados.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          paginate: true,
          page: currentPage.value,
        },
      })
      // eslint-disable-next-line consistent-return
      .then(res => {
        const { total } = res
        totalEmpleados.value = total

        let data = []
        data = res.data
        if (callback) callback(data)
        else return data
      })
      .catch(e => {
        console.error(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching empleados list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchEmpleadosAsList = () => {
    const body = isFiltering.value ? {
      search: searchQuery.value,
      dpto: filterDepartamento.value ? filterDepartamento.value.key : '',
      contrato_inicio: filterInicioContrato.value,
      contrato_fin: filterFinContrato.value,
      estado: filterEstadoEmpleado.value ? filterEstadoEmpleado.value.key : '',
      paginate: perPage.value,
    } : {
      search: searchQuery.value,
      paginate: perPage.value,
    }
    return store
      .dispatch('app-empleado/fetchEmpleados', {
        body,
        headers: {
          'X-Sort': `empleados.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          paginate: true,
          page: currentPage.value,
        },
      })
  }

  const fetchEmpleadosNoPaginate = async () => {
    let allEmpleados = []
    let currentPage = 1
    let hasMorePages = true

    while (hasMorePages) {
      const body = {
        search: searchQuery.value,
        paginate: 10000,
      }

      try {
        const res = await store.dispatch('app-empleado/fetchEmpleados', {
          body,
          headers: {
            'X-Sort': `empleados.${sortBy.value}`,
            'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
          },
          query: {
            paginate: true,
            page: currentPage,
          },
        })

        allEmpleados = [...allEmpleados, ...res.data]

        if (res.data.length < body.paginate || currentPage >= res.last_page) {
          hasMorePages = false
        } else {
          // eslint-disable-next-line no-plusplus
          currentPage++
        }
      } catch (error) {
        console.error('Error fetching empleados:', error)
        hasMorePages = false
      }
    }

    return {
      data: allEmpleados,
      total: allEmpleados.length,
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const parseDate = date => {
    let d = date.substring(0, 10)
    d = d.split('-').reverse().join('-')
    const t = date.substring(12, 19)
    return `${d} ${t}`
  }

  const resolveStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'
    if (status === 2) return 'secondary'
    return 'primary'
  }

  const resolveStatusText = status => {
    if (status === 0) return 'inactivo'
    if (status === 1) return 'activo'
    if (status === 2) return 'pendiente'
    return '-'
  }

  return {
    fetchEmpleados,
    tableColumns,
    perPage,
    currentPage,
    totalEmpleados,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEmpleadoListTable,

    refetchData,
    parseDate,
    resolveStatusVariant,
    resolveStatusText,

    // Extra Filters
    filterInicioContrato,
    filterFinContrato,
    filterDepartamento,
    filterEstadoEmpleado,
    filterData,
    isFiltering,
    clearFilter,

    fetchEmpleadosToState,
    isEmpleadoState,
    fetchEmpleadosAsList,
    fetchEmpleadosNoPaginate,
  }
}
